import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './BookAnAppointment.scss';
import emailjs from 'emailjs-com';

const BookAnAppointment = ({ setBookAnAppointment }) => {
    const [Page1, setPage1] = useState(true);
    const [Page2, setPage2] = useState(false);
    const [Page3, setPage3] = useState(false);
    const [FormSubmiting, setFormSubmiting] = useState(false);
    const [FormSubmited, setFormSubmited] = useState(false);
    const [CustInfoUpdate, setCustInfoUpdate] = useState(false);

    const [CustInfo, setCustInfo] = useState({
        FirstName: '',
        LastName: '',
        PhoneNumber: '',
        Email: '',
        Service: 'Chimney Inspection Lvl 1 | 99$',
        Date: new Date(new Date().getTime() + (24 * 60 * 60 * 1000)).toISOString().substr(0, 10),
        Time: '8:00AM-11:00AM',
        Address1: '',
        Address2: '',
        City: '',
        State: 'AL',
        Zipcode: '',
        Note: ''
    });

    const FinalMessage = `
      # Thousand Oaks, CA Chimney Sweep
      Name: ${CustInfo.FirstName + ' ' + CustInfo.LastName}
      Phone: ${CustInfo.PhoneNumber}
      Address: ${CustInfo.Address1 + ', ' + CustInfo.City + ', ' + CustInfo.State + ', ' + CustInfo.Zipcode + ' ' + CustInfo.Address2}
      Type: ${CustInfo.Service}
      Time: ${CustInfo.Date.split("-")[1] + '/' + CustInfo.Date.split("-")[2] + '/' + CustInfo.Date.split("-")[0] + ' ' + CustInfo.Time}
      Note: ${CustInfo.Note}`

    const location = useLocation().pathname
    const navigate = useNavigate()

    function sendEmail(e) {
        setFormSubmiting(true)
        e.preventDefault();    //This is important, i'm not sure why, but the email won't send without it

        emailjs.sendForm('service_fqud6um', 'template_n0o8yjw', e.target, 'QeqW-bXHRNjRmhZ6I')
            .then((result) => {
                setPage1(false)
                setPage2(false)
                setPage3(true)
                setFormSubmiting(false)
                navigate('/thanks')
                // window.location.reload()  //This is if you still want the page to reload (since e.preventDefault() cancelled that behavior) 
            }, (error) => {
                console.log(error.text);
            });
    }

    return (
        <div className="BookAnAppointmentMainComponent" style={location == '/' || location == '/sitemap' ? { "top": "60px" } : { "top": "100px" }}>

            <div className="BookAnAppointmentMainComponentTop">
                <h1>Book an Appointment for Chimney Sweep in Thousand Oaks, CA</h1>
                <h2>Welcome to Thousand Oaks Chimney Sweep – Your Trusted Chimney Service Provider in Thousand Oaks, California</h2>
            </div>
            <div>
                <div className="BookAnAppointmentMainComponentMaps">
                    <h4 style={Page1 || Page2 || Page3 ? {
                        "borderTop": "2px solid #2ea8ee"
                    } : null}>1. Information </h4>
                    <h4 style={Page2 || Page2 || Page3 ? {
                        "borderTop": "2px solid #2ea8ee"
                    } : null}>2. Address </h4>
                    <h4 style={Page3 ? {
                        "borderTop": "2px solid #2ea8ee"
                    } : null}>3. Note </h4>
                </div>
                {
                    Page1 ?
                        <div className="BookAnAppointmentMainComponentPage1">
                            <div className="BookAnAppointmentMainRightFullName">
                                <div className="BookAnAppointmentMainRightBox">
                                    <h4>First Name <span>*</span></h4>
                                    <input
                                        defaultValue={CustInfo.FirstName}
                                        className='BookAnAppointmentMainRightBoxFirstName'
                                        onChange={(e) => {
                                            CustInfo.FirstName = e.target.value
                                            setCustInfoUpdate(e => !e)
                                            console.log(CustInfo)
                                        }} type="text" />
                                </div>
                                <div className="BookAnAppointmentMainRightBox">
                                    <h4>Last Name <span>*</span></h4>
                                    <input
                                        defaultValue={CustInfo.LastName}
                                        className='BookAnAppointmentMainRightBoxLastName'
                                        onChange={(e) => {
                                            CustInfo.LastName = e.target.value
                                            setCustInfoUpdate(e => !e)
                                            console.log(CustInfo)
                                        }} type="text" />
                                </div>
                            </div>
                            <div className="BookAnAppointmentMainRightPhoneAndMaill">
                                <div className="BookAnAppointmentMainRightBox">
                                    <h4>Phone Number <span>*</span></h4>
                                    <input
                                        defaultValue={CustInfo.PhoneNumber}
                                        className='BookAnAppointmentMainRightBoxPhone'
                                        onChange={(e) => {
                                            CustInfo.PhoneNumber = e.target.value
                                            setCustInfoUpdate(e => !e)
                                            console.log(CustInfo)
                                        }} type="tel" />
                                </div>
                                <div className="BookAnAppointmentMainRightBox">
                                    <h4>Email Address (Optional)</h4>
                                    <input
                                        defaultValue={CustInfo.Email}
                                        onChange={(e) => {
                                            CustInfo.Email = e.target.value
                                            setCustInfoUpdate(e => !e)
                                            console.log(CustInfo)
                                        }} type="text" />
                                </div>
                            </div>
                            <div className="BookAnAppointmentMainRightServices">
                                <div className="BookAnAppointmentMainRightBox">
                                    <h4>Service Needed <span>*</span></h4>
                                    <select
                                        defaultValue={CustInfo.Service}
                                        onChange={(e) => {
                                            CustInfo.Service = e.target.value
                                            setCustInfoUpdate(e => !e)
                                            console.log(CustInfo)
                                        }} name="" id="">
                                        <option value="Chimney Inspection Lvl 1 | 99$">Chimney Inspection Lvl 1 | 99$</option>
                                        <option value="Chimney Inspection Lvl 2 | 149$">Chimney Inspection Lvl 2 | 149$</option>
                                        <option value="Chimney Inspection Lvl 1 & Cleaning | 149$">Chimney Inspection Lvl 1 & Cleaning | 149$</option>
                                        <option value="Chimney Inspection Lvl 2 & Cleaning | 199$">Chimney Inspection Lvl 2 & Cleaning | 199$</option>
                                    </select>
                                </div>
                            </div>
                            <div className="BookAnAppointmentMainRightDateAndTime">
                                <div className="BookAnAppointmentMainRightBox">
                                    <h4>Appointment Date <span>*</span></h4>
                                    <input
                                        defaultValue={CustInfo.Date}
                                        className='BookAnAppointmentMainRightBoxDate'
                                        onChange={(e) => {
                                            CustInfo.Date = e.target.value
                                            setCustInfoUpdate(e => !e)
                                            console.log(CustInfo)
                                        }} type="date" />
                                </div>
                                <div className="BookAnAppointmentMainRightBox">
                                    <h4>Appointment Time <span>*</span></h4>
                                    <select
                                        defaultValue={CustInfo.Time}
                                        className='BookAnAppointmentMainRightBoxTime'
                                        onChange={(e) => {
                                            CustInfo.Time = e.target.value
                                            setCustInfoUpdate(e => !e)
                                            console.log(CustInfo)
                                        }} >
                                        <option value="8:00AM-11:00AM">8:00AM-11:00AM</option>
                                        <option value="11:00AM-01:00PM">11:00AM-01:00PM</option>
                                        <option value="01:00PM-03:00PM">01:00PM-04:00PM</option>
                                        <option value="04:00PM-07:00PM">04:00PM-07:00PM</option>
                                        <option value="Ask for a Representative">Ask for a Representative</option>
                                    </select>
                                </div>
                            </div>
                            <div className="BookAnAppointmentMainComponentButtons">
                                <button onClick={() => {

                                    if (!CustInfo.FirstName) { document.querySelector(".BookAnAppointmentMainRightBoxFirstName").style.border = "1px solid red"; } else { document.querySelector(".BookAnAppointmentMainRightBoxFirstName").style.border = "1px solid #6f6f6f61"; }
                                    if (!CustInfo.LastName) { document.querySelector(".BookAnAppointmentMainRightBoxLastName").style.border = "1px solid red"; } else { document.querySelector(".BookAnAppointmentMainRightBoxLastName").style.border = "1px solid #6f6f6f61"; }
                                    if (!CustInfo.PhoneNumber) { document.querySelector(".BookAnAppointmentMainRightBoxPhone").style.border = "1px solid red"; } else { document.querySelector(".BookAnAppointmentMainRightBoxPhone").style.border = "1px solid #6f6f6f61"; }
                                    if (!CustInfo.Date) { document.querySelector(".BookAnAppointmentMainRightBoxDate").style.border = "1px solid red"; } else { document.querySelector(".BookAnAppointmentMainRightBoxDate").style.border = "1px solid #6f6f6f61"; }
                                    if (!CustInfo.Time) { document.querySelector(".BookAnAppointmentMainRightBoxTime").style.border = "1px solid red"; } else { document.querySelector(".BookAnAppointmentMainRightBoxTime").style.border = "1px solid #6f6f6f61"; }

                                    if (
                                        CustInfo.FirstName &&
                                        CustInfo.LastName &&
                                        CustInfo.PhoneNumber &&
                                        CustInfo.Service &&
                                        CustInfo.Date &&
                                        CustInfo.Time
                                    ) {
                                        setPage1(false)
                                        setPage2(true)
                                    } else {
                                        console.log('no')
                                    }
                                }}>NEXT</button>
                            </div>
                        </div>
                        : null
                }
                {
                    Page2 ?
                        <div className="BookAnAppointmentMainComponentPage2">
                            <div className="BookAnAppointmentMainRightAddress1">
                                <div className="BookAnAppointmentMainRightBox">
                                    <h4>Address Line 1 <span>*</span></h4>
                                    <input
                                        className='BookAnAppointmentMainRightBoxAddress1'
                                        defaultValue={CustInfo.Address1}
                                        onChange={(e) => {
                                            CustInfo.Address1 = e.target.value
                                            setCustInfoUpdate(e => !e)
                                            console.log(CustInfo)
                                        }} type="text" />
                                </div>
                            </div>
                            <div className="BookAnAppointmentMainRightAddress2">
                                <div className="BookAnAppointmentMainRightBox">
                                    <h4>Address Line 2 (Optional)</h4>
                                    <input
                                        defaultValue={CustInfo.Address2}
                                        onChange={(e) => {
                                            CustInfo.Address2 = e.target.value
                                            setCustInfoUpdate(e => !e)
                                            console.log(CustInfo)
                                        }} type="text" />
                                </div>
                            </div>
                            <div className="BookAnAppointmentMainRightAddress3">
                                <div className="BookAnAppointmentMainRightBox">
                                    <h4>City <span>*</span></h4>
                                    <input
                                        className='BookAnAppointmentMainRightBoxCity'
                                        defaultValue={CustInfo.City}
                                        onChange={(e) => {
                                            CustInfo.City = e.target.value
                                            setCustInfoUpdate(e => !e)
                                            console.log(CustInfo)
                                        }} type="text" />
                                </div>
                                <div className="BookAnAppointmentMainRightBox">
                                    <h4>State <span>*</span></h4>
                                    <select
                                        defaultValue={CustInfo.State}
                                        onChange={(e) => {
                                            CustInfo.State = e.target.value
                                            setCustInfoUpdate(e => !e)
                                            console.log(CustInfo)
                                        }}>
                                        <option value="AL">Alabama</option>
                                        <option value="AK">Alaska</option>
                                        <option value="AZ">Arizona</option>
                                        <option value="AR">Arkansas</option>
                                        <option value="CA">California</option>
                                        <option value="CO">Colorado</option>
                                        <option value="CT">Connecticut</option>
                                        <option value="DE">Delaware</option>
                                        <option value="DC">District Of Columbia</option>
                                        <option value="FL">Florida</option>
                                        <option value="GA">Georgia</option>
                                        <option value="HI">Hawaii</option>
                                        <option value="ID">Idaho</option>
                                        <option value="IL">Illinois</option>
                                        <option value="IN">Indiana</option>
                                        <option value="IA">Iowa</option>
                                        <option value="KS">Kansas</option>
                                        <option value="KY">Kentucky</option>
                                        <option value="LA">Louisiana</option>
                                        <option value="ME">Maine</option>
                                        <option value="MD">Maryland</option>
                                        <option value="MA">Massachusetts</option>
                                        <option value="MI">Michigan</option>
                                        <option value="MN">Minnesota</option>
                                        <option value="MS">Mississippi</option>
                                        <option value="MO">Missouri</option>
                                        <option value="MT">Montana</option>
                                        <option value="NE">Nebraska</option>
                                        <option value="NV">Nevada</option>
                                        <option value="NH">New Hampshire</option>
                                        <option value="NJ">New Jersey</option>
                                        <option value="NM">New Mexico</option>
                                        <option value="NY">New York</option>
                                        <option value="NC">North Carolina</option>
                                        <option value="ND">North Dakota</option>
                                        <option value="OH">Ohio</option>
                                        <option value="OK">Oklahoma</option>
                                        <option value="OR">Oregon</option>
                                        <option value="PA">Pennsylvania</option>
                                        <option value="RI">Rhode Island</option>
                                        <option value="SC">South Carolina</option>
                                        <option value="SD">South Dakota</option>
                                        <option value="TN">Tennessee</option>
                                        <option value="TX">Texas</option>
                                        <option value="UT">Utah</option>
                                        <option value="VT">Vermont</option>
                                        <option value="VA">Virginia</option>
                                        <option value="WA">Washington</option>
                                        <option value="WV">West Virginia</option>
                                        <option value="WI">Wisconsin</option>
                                        <option value="WY">Wyoming</option>
                                    </select>
                                </div>
                                <div className="BookAnAppointmentMainRightBox">
                                    <h4>Zipcode <span>*</span></h4>
                                    <input
                                        className='BookAnAppointmentMainRightBoxZipcode'
                                        defaultValue={CustInfo.Zipcode}
                                        onChange={(e) => {
                                            CustInfo.Zipcode = e.target.value
                                            setCustInfoUpdate(e => !e)
                                            console.log(CustInfo)
                                        }} type="tel" />
                                </div>
                            </div>
                            <div className="BookAnAppointmentMainComponentButtons">
                                <button onClick={() => {

                                    setPage1(true)
                                    setPage2(false)
                                    setPage3(false)
                                }}>PREVIOUS</button>
                                <button onClick={() => {
                                    console.log(CustInfo)
                                    if (!CustInfo.Address1) { document.querySelector(".BookAnAppointmentMainRightBoxAddress1").style.border = "1px solid red"; } else { document.querySelector(".BookAnAppointmentMainRightBoxAddress1").style.border = "1px solid #6f6f6f61"; }
                                    if (!CustInfo.City) { document.querySelector(".BookAnAppointmentMainRightBoxCity").style.border = "1px solid red"; } else { document.querySelector(".BookAnAppointmentMainRightBoxCity").style.border = "1px solid #6f6f6f61"; }
                                    if (!CustInfo.Zipcode) { document.querySelector(".BookAnAppointmentMainRightBoxZipcode").style.border = "1px solid red"; } else { document.querySelector(".BookAnAppointmentMainRightBoxZipcode").style.border = "1px solid #6f6f6f61"; }

                                    if (
                                        CustInfo.Address1 &&
                                        CustInfo.City &&
                                        CustInfo.Zipcode
                                    ) {
                                        setPage1(false)
                                        setPage2(false)
                                        setPage3(true)
                                    } else {
                                        console.log('no')
                                    }

                                }}>NEXT</button>
                            </div>
                        </div>
                        : null
                }
                {
                    Page3 ?
                        <div className="BookAnAppointmentMainComponentPage3">
                            <div className="BookAnAppointmentMainRightNote">
                                <div className="BookAnAppointmentMainRightBox">
                                    <h4>Note</h4>
                                    <textarea
                                        defaultValue={CustInfo.Note}
                                        onChange={(e) => {
                                            CustInfo.Note = e.target.value
                                            setCustInfoUpdate(e => !e)
                                            console.log(CustInfo)
                                        }} type="text" />
                                </div>
                            </div>
                            <div className="BookAnAppointmentMainComponentButtons">
                                <button onClick={() => {
                                    setPage1(false)
                                    setPage2(true)
                                    setPage3(false)
                                }}>PREVIOUS</button>

                                {
                                    !FormSubmiting ?
                                        <form className="contact-form" onSubmit={sendEmail}>
                                            <input type="hidden" name="from_name" defaultValue={FinalMessage} />
                                            {/* Cust Info */}
                                            <input type="hidden" name="Date" defaultValue={CustInfo.Date.split("-")[1] + '/' + CustInfo.Date.split("-")[2] + '/' + CustInfo.Date.split("-")[0] + ' ' + CustInfo.Time} />
                                            <input type="hidden" name="Service_Type" defaultValue={CustInfo.Service} />
                                            <input type="hidden" name="Send_To" defaultValue={CustInfo.Email} />
                                            <input type="hidden" name="Cust_Name" defaultValue={CustInfo.FirstName} />
                                            <input type="hidden" name="Company_From" defaultValue={`Thousand Oaks Chimney Sweep`} />
                                            <input type="hidden" name="Company" defaultValue={`Thousand Oaks Chimney Sweep | ${CustInfo.City + ', ' + CustInfo.State}`} />
                                            <input type="submit" value="SEND" />
                                        </form>
                                        :
                                        null
                                }

                                {
                                    FormSubmiting ?
                                        <button class="buttonload">
                                            <i class="fa fa-refresh fa-spin"></i>Loading
                                        </button>
                                        :
                                        null
                                }
                            </div>
                        </div>
                        : null
                }
            </div>
            <div className="BookAnAppointmentMainComponentContent">
                    <p><strong>Welcome to Your Trusted Chimney Service Provider in Thousand Oaks, California</strong></p>
                    <p>We pride ourselves on delivering top-notch chimney services to ensure the safety and efficiency of your home. Whether you need a routine sweep, a detailed inspection, or urgent repairs, our skilled professionals are here to help. Booking an appointment with us is simple and straightforward. Read on to learn more about our services, why regular chimney maintenance is crucial, and how you can easily schedule your next chimney service.</p><h4>Why Choose Our Chimney Services?</h4>
                    <p><strong>1. Expertise and Experience</strong></p>
                    <p>With over a decade of experience, we have established ourselves as a leading provider of chimney services in Thousand Oaks, CA. Our certified technicians are trained to handle all types of chimneys and fireplaces, ensuring your home remains safe and warm.</p>
                    <p><strong>2. Comprehensive Chimney Services</strong></p>
                    <p>We offer a wide range of chimney services, including sweeping, cleaning, inspection, and repair. Our team uses state-of-the-art equipment to provide thorough and efficient service, ensuring your chimney is in top condition.</p>
                    <p><strong>3. Customer Satisfaction</strong></p>
                    <p>Customer satisfaction is our top priority. We strive to provide exceptional service with a focus on quality, reliability, and professionalism. Our team is dedicated to meeting your needs and exceeding your expectations.</p><h4>Our Chimney Services</h4>
                    <p><strong>Chimney Sweeping</strong></p>
                    <p>Regular chimney sweeping is essential to remove soot, creosote, and debris that can accumulate over time. These substances can obstruct the chimney, reducing its efficiency and increasing the risk of chimney fires. Our comprehensive sweeping service ensures your chimney is clean and safe to use.</p>
                    <p><strong>Chimney Inspection</strong></p>
                    <p>A thorough inspection is crucial to identify potential issues and prevent costly repairs down the line. Our detailed inspection process includes checking for structural integrity, blockages, and signs of damage. We provide a complete report with recommendations for any necessary repairs or maintenance.</p>
                    <p><strong>Chimney Repair</strong></p>
                    <p>From minor repairs to major restoration, our experienced technicians can handle all your chimney repair needs. Whether it's fixing cracks, replacing damaged components, or addressing water leaks, we use high-quality materials and expert craftsmanship to restore your chimney to optimal condition.</p>
                    <p><strong>Chimney Cleaning</strong></p>
                    <p>In addition to sweeping, we offer deep cleaning services to remove stubborn creosote buildup and other contaminants. This service helps improve your chimney's efficiency and extends its lifespan, ensuring it functions properly for years to come.</p>
                    <p><strong>Chimney Relining</strong></p>
                    <p>If your chimney liner is damaged or deteriorating, it can pose a significant safety risk. Our chimney relining service involves installing a new, durable liner to protect your home from harmful gases and improve the chimney's efficiency.</p><h4>Why Regular Chimney Maintenance is Crucial</h4>
                    <p><strong>Prevent Chimney Fires</strong></p>
                    <p>Creosote, a highly flammable substance, can build up inside your chimney over time. Regular sweeping and cleaning remove this dangerous buildup, reducing the risk of chimney fires and keeping your home safe.</p>
                    <p><strong>Ensure Proper Ventilation</strong></p>
                    <p>A blocked or inefficient chimney can lead to poor ventilation, causing smoke and harmful gases to enter your home. Routine maintenance ensures your chimney is clear and functioning correctly, providing proper ventilation and air quality.</p>
                    <p><strong>Extend Chimney Lifespan</strong></p>
                    <p>Regular inspections and maintenance help identify and address minor issues before they become major problems. This proactive approach extends the lifespan of your chimney, saving you money on costly repairs or replacements in the future.</p>
                    <p><strong>Maintain Home Value</strong></p>
                    <p>A well-maintained chimney adds value to your home. Whether you're planning to sell your home or simply want to keep it in top condition, regular chimney maintenance is a smart investment that pays off in the long run.</p><h4>How to Book an Appointment</h4>
                    <p>Booking an appointment with us is easy and convenient. Follow these simple steps to schedule your chimney service:</p>
                    <p><strong>1. Contact Us</strong></p>
                    <p>You can reach us by phone, email, or through our online booking system. Our friendly customer service team is available to answer any questions and assist you in scheduling an appointment.</p>
                    <p><strong>2. Choose Your Service</strong></p>
                    <p>Let us know which chimney service you require. Whether it's sweeping, inspection, repair, or cleaning, we offer a variety of services to meet your needs.</p>
                    <p><strong>3. Select a Convenient Time</strong></p>
                    <p>We understand that your time is valuable. We offer flexible scheduling options to accommodate your busy lifestyle. Choose a date and time that works best for you, and we'll do our best to accommodate your request.</p>
                    <p><strong>4. Confirm Your Appointment</strong></p>
                    <p>Once you've selected your service and preferred time, we'll confirm your appointment and provide any necessary details. We'll also send a reminder closer to the date to ensure you don't miss your scheduled service.</p>
                    <p><strong>5. Prepare for Our Visit</strong></p>
                    <p>Before our technicians arrive, make sure the area around your chimney is clear and accessible. This allows us to work efficiently and complete the service in a timely manner.</p>
                    <p><strong>6. Receive Expert Service</strong></p>
                    <p>On the day of your appointment, our experienced technicians will arrive on time and ready to work. They'll conduct the necessary service with professionalism and attention to detail, ensuring your chimney is in excellent condition.</p>
                    <p><strong>7. Follow-Up and Support</strong></p>
                    <p>After the service, we'll provide a detailed report of the work performed and any recommendations for future maintenance. If you have any questions or concerns, our customer support team is always available to assist you.</p><h4>Frequently Asked Questions</h4>
                    <p><strong>Q: How often should I have my chimney swept?</strong></p>
                    <p>A: It's recommended to have your chimney swept at least once a year, especially if you use it frequently. Regular sweeping helps prevent buildup of soot and creosote, ensuring safe and efficient operation.</p>
                    <p><strong>Q: What are the signs that my chimney needs repair?</strong></p>
                    <p>A: Common signs include visible cracks, water leaks, smoke entering your home, and a strong odor coming from the chimney. If you notice any of these issues, it's important to schedule an inspection and repair as soon as possible.</p>
                    <p><strong>Q: Can I clean my chimney myself?</strong></p>
                    <p>A: While there are DIY methods for cleaning a chimney, it's best to leave it to the professionals. Our trained technicians have the tools and expertise to perform a thorough and safe cleaning, minimizing the risk of damage or injury.</p>
                    <p><strong>Q: What should I do if I have a chimney fire?</strong></p>
                    <p>A: If you suspect a chimney fire, evacuate your home immediately and call 911. Once the fire is out, contact us to inspect and repair any damage to your chimney.</p>
                    <p><strong>Q: How can I improve my chimney's efficiency?</strong></p>
                    <p>A: Regular maintenance, including sweeping, cleaning, and inspection, helps improve your chimney's efficiency. Additionally, installing a chimney cap and ensuring proper ventilation can enhance performance and reduce energy costs.</p><h4>Contact Us Today</h4>
                    <p>Don't wait until there's a problem with your chimney. Regular maintenance is key to ensuring your home's safety and comfort. Contact us today to schedule your chimney service appointment in Thousand Oaks, CA. Our team is ready to provide the professional, reliable service you deserve.</p>
                    <p>We look forward to serving you and ensuring your chimney is in top condition.</p>
                    <p>This comprehensive booking page includes detailed information about the services offered, the importance of regular maintenance, and easy steps to book an appointment. Feel free to customize any part of this content to better fit your specific needs.</p>
                </div>

        </div >
    );
}

export default BookAnAppointment;
